import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageWrapper from "../components/shared/PageWrapper"
import { HiDownload } from "react-icons/hi"
import { Link } from "gatsby"
import Button from "../components/shared/Button"
import policiesPdf from "../downloads/studio-policies202324.pdf"

const AboutPage = () => (
  <Layout>
    <Seo title="Policies" />
    <PageWrapper prose>
      <h1 className="text-3xl text-indigo-500">Rates &amp; Policies</h1>
      <Button subdued className="mt-4 mb-8">
        <a href={policiesPdf} className="flex gap-2 items-center" download>
          Download pdf <HiDownload />
        </a>
      </Button>
      <h2 className="text-2xl text-indigo-500">Tuition</h2>
      <p className="mb-4">
        My teaching year is divided into two semesters. A monthly payment
        reserves your spot in my studio and covers other items such as lesson
        preparation, repertoire research, and recital preparation.
      </p>
      <table className="w-full text-sm my-4">
        <thead className="font-bold">
          <td className="py-1 px-2">Semester 1 – Fall 2023</td>
          <td className="py-1 px-2">Semester 2 - Spring 2024</td>
        </thead>
        <tbody>
          <tr className="bg-slate-100">
            <td className="py-1 px-2">12 Weeks (September – December)</td>
            <td className="py-1 px-2">20 weeks (January – June)</td>
          </tr>
          <tr>
            <td className="py-1 px-2">2 Flex weeks</td>
            <td className="py-1 px-2">3 Flex weeks</td>
          </tr>
          <tr className="bg-slate-100">
            <td className="pt-1 px-2">Monthly payments (4)</td>
            <td className="pt-1 px-2">Monthly payments (6)</td>
          </tr>
          <tr className="bg-slate-100">
            <td className="pb-1 px-2">
              <ul className="list-disc ml-6">
                <li>30min – $105</li>
                <li>45min – $135</li>
                <li>60min $165</li>
              </ul>
            </td>
            <td className="pb-1 px-2">
              <ul className="list-disc ml-6">
                <li>30min – $117</li>
                <li>45min – $150</li>
                <li>60min – $183</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="py-1 px-2">Limit of 2 lesson credits</td>
            <td className="py-1 px-2">Limit of 3 lesson credits</td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl text-indigo-500">Drop-in Lessons</h2>
      <p className="mb-4">
        For most students, I strongly recommend a weekly lesson as the best way
        to make consistent progress. For more advanced students and professional
        singers or where a weekly lesson isn’t possible, occasional lessons can
        be booked when my schedule allows.
      </p>
      <h3 className="font-bold text-slate-500">DROP-IN RATE</h3>
      <p className="mb-4">$70/hr</p>
      <h2 className="text-2xl text-indigo-500">Missed Lessons</h2>
      <p className="mb-4">
        No refunds will be given for missed or cancelled lessons.
      </p>
      <h3 className="font-bold text-slate-500">LESSON CREDITS</h3>
      <p className="mb-4">
        If you find yourself unable to attend a lesson, you may be issued lesson
        credits at the discretion of the instructor. Lesson credits can be
        redeemed in the flex weeks at the end of each semester. Lesson credits
        will only be issued if I am notified of the absence at least 24 hours in
        advance. No credits will be given for lessons missed with less than 24
        hours notice.
      </p>
      <h3 className="font-bold text-slate-500">ILLNESS</h3>
      <p className="mb-4">
        An exception to the 24 hour policy will be made in the case of illness,
        provided the student notifies us by 9am the day of the lesson. Lessons
        cancelled due to illness still count toward the limits on lesson credits
        outlined above. No Lesson credit will be issued if the student is unable
        to attend because a parent or sibling of the student is ill.
      </p>
      <h3 className="font-bold text-slate-500">WEATHER</h3>
      <p className="mb-4">
        No lesson credit will be issued for lessons missed due to bad weather.
        If you are unable to attend your lesson because of the weather, I can
        arrange to do the lesson at your usual time via Zoom, or arrange a phone
        call to discuss your progress.
      </p>
      <h3 className="font-bold text-slate-500">TEACHER CANCELLATIONS</h3>
      <p className="mb-4">
        In addition to teaching, I maintain an active performing schedule.
        Although I try to keep conflicts to a minimum, this may mean I have to
        reschedule your lesson from time to time. We will try my best to find
        another time that works for you. If no suitable time can be found, a
        lesson credit will be issued to be redeemed during the flex weeks. This
        credit will not count toward your limit for the semester.
      </p>
    </PageWrapper>
  </Layout>
)

export default AboutPage
